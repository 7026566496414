<template>
  <div class="pb-5">
    <div class="d-flex align-items-center mb-4">
      <div class="col-md-6">
        <a @click="$router.back()" class="text-dark d-flex align-items-center cursor-pointer">
          <span class="d-flex align-items-center">
            <vs-icon icon="chevron_left"></vs-icon>
          </span>
          <span class="text-underline">Back</span>
        </a>
      </div>
      <div class="col-md-6">
        <div class="text-right">
          <button v-if="isFeatureActive" @click="removeArtist()" class="btn btn-primary btn-site-outline px-3">
            Remove Artist
          </button>
        </div>
      </div>
    </div>
    <SectionLoading v-if="loading" text="Artist profile loading..." />
    <div v-else>
      <div>
        <div class="row">
          <div class="col-md-2">
            <div class="user-verified">
              <vs-avatar class="w-full" :src="
                artistData.profile_photo_url ||
                'https://via.placeholder.com/150'
              " size="160px" />
              <vs-icon v-if="artistData.email_verified_at" icon="verified_user"></vs-icon>
            </div>
          </div>
          <div class="col-md-10">
            <div class="releaseData">
              <p class="type">
                {{ artistData.release_count }} Product<span v-if="artistData.release_count > 0">S</span>
              </p>
              <h1 class="title mb-3">{{ artistData.artist_name }}</h1>
              <p class="mb-1">
                <span class="text-dark">{{
                    artistData.name | capitalize
                }}</span>
              </p>
              <p class="releaseinf">
                <span class="titl">LAST SEEN: </span>
                <span class="text-dark" v-if="artistData.last_seen">{{
                    moment.utc(new Date(artistData.last_seen)).fromNow()
                }}</span>
                <span v-else> - </span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-3">
          <div class="card siteCard mt-3">
            <div class="p-3">
              <p class="text-dark font-weight-bold">Location</p>
              <div class="text-dark d-flex align-items-center">
                <vs-icon icon="location_on"></vs-icon>
                <span class="pl-2"> {{ artistData.country }} </span>
              </div>
            </div>
          </div>

          <div class="card siteCard mt-3">
            <div class="p-3">
              <p class="text-dark font-weight-bold">Contact</p>
              <a :href="`mailto:${artistData.email}`" target="_blank" class="text-dark d-flex align-items-center mb-2">
                <span class="font-weight-bold">EM</span>
                <span class="pl-2"> {{ artistData.email }} </span>
              </a>

              <a :href="`tel:${artistData.phone}`" target="_blank" class="text-dark d-flex align-items-center mb-2">
                <span class="font-weight-bold">PH</span>
                <span class="pl-2"> {{ artistData.phone }} </span>
              </a>
            </div>
          </div>

          <a v-if="artistData.spotify_id" :href="`https://open.spotify.com/artist/${artistData.spotify_id}`"
            target="_blank" class="card siteCard spotify-card mt-3">
            <div class="p-3">
              <img src="/images/icon-spotify.svg" alt="spotify" />
              <span class="text-white font-weight-bold mb-0">
                View Spotify Profile
              </span>
            </div>
          </a>

          <div class="card siteCard mt-3" v-if="artistData.socials.length > 0">
            <div class="p-3">
              <p class="text-dark font-weight-bold">Connect</p>
              <a v-for="(social, index) in artistData.socials" :href="getSocialLink(social)" :key="index"
                target="_blank" class="text-dark d-flex align-items-center mb-2">
                <span v-if="social.platform == 'instagram'" class="font-weight-bold">IG</span>
                <span v-else-if="social.platform == 'twitter'" class="font-weight-bold">TW</span>
                <span v-else-if="social.platform == 'facebook'" class="font-weight-bold">FB</span>
                <span class="pl-2">
                  <span>
                    {{ social.link }}
                  </span>
                </span>
              </a>
            </div>
          </div>

          <div class="card siteCard mt-3" v-if="artistData.bank">
            <div class="p-3">
              <p class="text-dark font-weight-bold">Bank Information</p>
              <p class="text-dark d-flex align-items-center mb-1">
                <span class="font-weight-bold">{{
                    artistData.bank.account_name
                }}</span>
              </p>

              <p class="text-dark d-flex align-items-center mb-1">
                <span class="">{{ artistData.bank.account_number }}</span>
              </p>
              <p class="text-dark d-flex align-items-center mb-2">
                <span>{{ artistData.bank.bank_name }}</span>
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-9">
          <div class="card siteCard mt-3" v-if="artistData.subscription">
            <div class="p-3">
              <p class="creatorTitle mb-3">Subscription</p>
              <button class="btn btn-sm btn-dark float-right" @click="updatePlanModal = true">
                Change plan
              </button>
              <div class="row">
                <div class="col-md-3">
                  <div class="metaDisplay">
                    <p class="headi">Start Date</p>
                    <p class="data">
                      {{
                          artistData.subscription.status == 'active' ?
                            moment
                              .utc(new Date(artistData.subscription.start_date))
                              .format("DD/MM/YYYY") : '-'
                      }}
                    </p>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="metaDisplay">
                    <p class="headi">End Date</p>
                    <p class="data">
                      {{
                          artistData.subscription.status == 'active' ?
                            moment
                              .utc(new Date(artistData.subscription.end_date))
                              .format("DD/MM/YYYY") : '-'
                      }}
                    </p>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="metaDisplay">
                    <p class="headi">Status</p>
                    <p class="data">
                      <span v-if="artistData.subscription"
                        class="text-uppercase"
                        :class="artistData.subscription.status === 'active' ? 'text-success' : 'text-danger'"
                      >
                        {{ artistData.subscription.status }}
                      </span>
                    </p>
                  </div>
                </div>
              </div>

              <div v-if="artistData.subscription && artistData.subscription.plan">
                <p class="creatorTitle mb-3">Plan</p>
                <div class="row">
                  <div class="col-md-3">
                    <div class="metaDisplay">
                      <p class="headi">Plan name</p>
                      <p class="data">
                        {{ artistData.subscription.plan.name }}
                      </p>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="metaDisplay">
                      <p class="headi">Amount - Yearly</p>
                      <p class="data">
                        {{
                            artistData.subscription.plan.amount_yearly
                            | currency("₦", 0)
                        }}
                      </p>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="metaDisplay">
                      <p class="headi">Amount - Monthly</p>
                      <p class="data">
                        {{
                            artistData.subscription.plan.amount_monthly
                            | currency("₦", 0)
                        }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="pt-4">
            <h5 class="text-dark mb-3">Singles, EPs and Albums</h5>
            <div class="row">
              <div class="col-md-4" v-for="(release, index) in artistData.releases" :key="index">
                <div class="card siteCard productsCard" @click="$router.push(`/release/${release.uuid}`)">
                  <div class="display">
                    <img :src="release.cover_image_path || ''" class="card-img-top" alt="..." />
                    <div class="cardlist">
                      <span>{{ release.type }}</span>
                    </div>
                  </div>
                  <div class="info">
                    <h5 class="text-primary title">{{ release.title }}</h5>
                    <p class="date">
                      {{ getReleaseDate(release.release_date) }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card siteCard mt-3">
        <div class="p-3">
          <div class="row">
            <div class="col-md-3">
              <div>
                <p class="creatorTitle">Total Stream Count</p>
                <h3 class="my-2">
                  <span class="text-dark font-weight-bold">{{
                      artistAnalytics.total_streams | number("0,0")
                  }}</span>
                </h3>
              </div>

              <div>
                <p class="creatorTitle">Total Downloads</p>
                <h3 class="my-2">
                  <span class="text-dark font-weight-bold">{{
                      artistAnalytics.total_downloads | number("0,0")
                  }}</span>
                </h3>
              </div>

              <div v-if="artistData.wallet">
                <p class="creatorTitle">
                  User Wallet Balance (After Deductions)
                </p>
                <h3 class="my-2">
                  <span class="text-dark font-weight-bold">{{
                      artistData.wallet.balance_after_deduction | currency("€", 2)
                  }}</span>
                </h3>
              </div>
            </div>

            <div class="col-md-3">
              <div>
                <p class="creatorTitle">Total Earnings</p>
                <h3 class="my-2">
                  <span class="text-dark font-weight-bold">{{
                      artistAnalytics.user_earnings || 0 | currency("€", 2)
                  }}</span>
                </h3>
              </div>

              <div>
                <p class="creatorTitle">Total Earnings After Deductions</p>
                <h3 class="my-2">
                  <span class="text-dark font-weight-bold">{{
                      artistAnalytics.user_earnings_after_deduction ||
                      0 | currency("€", 2)
                  }}</span>
                </h3>
              </div>

              <div>
                <p class="creatorTitle">
                  Total Earnings After Deductions (User Currency)
                </p>
                <h3 class="my-2">
                  <span class="text-dark font-weight-bold">{{
                      artistAnalytics.user_earnings_after_deduction_converted ||
                      0
                      | currency(
                        getCurrencySymbol(artistAnalytics.currency) || "",
                        2
                      )
                  }}</span>
                </h3>
              </div>
            </div>
            <div class="col-md-6">
              <v-chart class="chart" :option="infoChart" />
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="card siteCard mt-3">
            <div class="p-3">
              <p class="text-dark font-weight-bold">Platform Streams</p>
              <v-chart class="streamChart" :option="streamsChart" />
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card siteCard mt-3">
            <div class="p-3">
              <p class="text-dark font-weight-bold">Platform Downloads</p>
              <v-chart class="streamChart" :option="downloadsChart" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <vs-popup class="rateModal" :title="`Update ${artistData.name} Plan`" :active.sync="updatePlanModal">
      <form class="p-4" @submit.prevent="submitFormPlan" role="form">
        <div class="form-group select-input">
          <div class="plan-select">
            <vs-radio v-for="(plan, index) in allPlans" :key="index" v-model="plan_id" vs-name="plan_id"
              :vs-value="plan.id">
              <div class="pl-2">
                <p class="text-dark mb-0">{{ plan.name }}</p>
                <p class="text-small text-uppercase text-dark mb-0" style="font-size: 13px; letter-spacing: 1px">
                  {{ plan.for }}
                </p>
              </div>
            </vs-radio>
          </div>
        </div>

        <hr />
        <div class="form-group select-input">
          <label class="text-dark" to="end_date">Select End date</label>
          <div>
            <date-picker v-model="end_date" format="YYYY-MM-DD" type="date"
              :disabled-date="disabledBeforeTodayAndAfterAWeek" placeholder="Select end date"></date-picker>
          </div>
        </div>

        <div class="form-group text-center mt-5">
          <button :disabled="btnLoading || (plan_id === null && end_date === null)" type="submit"
            class="btn btn-primary btn-block btn-site px-5">
            Update Plan
            <BtnLoading v-if="btnLoading" class="d-inline-block" height="18" />
          </button>
        </div>
      </form>
    </vs-popup>
  </div>
</template>
<script>
import SectionLoading from "../../../components/SectionLoading.vue";
import BtnLoading from "../../../components/BtnLoading";
import DatePicker from "vue2-datepicker";
import moment from "moment";
import "vue2-datepicker/index.css";

export default {
  name: "Release",
  components: { BtnLoading, DatePicker, SectionLoading },
  data() {
    return {
      loading: false,
      artistData: {},
      artistAnalytics: {},
      streamsChart: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // Use axis to trigger tooltip
            type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
          },
        },
        legend: {},
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "value",
        },
        yAxis: {
          type: "category",
          data: [],
        },
        series: [
          {
            name: "Counts",
            type: "bar",
            stack: "total",
            label: {
              show: true,
            },
            color: ["#f88f80", "#f8c9a0", "#f8f0a0", "#a0f8a0", "#a0f8f0"],
            emphasis: {
              focus: "series",
            },
            data: [],
          },
        ],
      },
      downloadsChart: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // Use axis to trigger tooltip
            type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
          },
        },
        legend: {},
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "value",
        },
        yAxis: {
          type: "category",
          data: [],
        },
        series: [
          {
            name: "Counts",
            type: "bar",
            stack: "total",
            label: {
              show: true,
            },
            color: ["#f8c9a0", "#f88f80", "#f8f0a0", "#a0f8a0", "#a0f8f0"],
            emphasis: {
              focus: "series",
            },
            data: [],
          },
        ],
      },

      infoChart: {
        tooltip: {
          trigger: "item",
        },
        legend: {
          orient: "vertical",
          left: "left",
        },
        series: [
          {
            name: "Access From",
            type: "pie",
            radius: ["40%", "70%"],
            color: ["#f88f80", "#f8c9a0", "#f8f0a0", "#a0f8a0", "#a0f8f0"],
            itemStyle: {
              borderRadius: 5,
            },
            data: [],

            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0)",
              },
            },
          },
        ],
      },
      updatePlanModal: false,
      btnLoading: false,
      allPlans: [],
      plan_id: null,
      end_date: null,
    };
  },
  mounted() {
    this.getArtistbyID();
    this.getArtistAnalytics();
    this.getPlans();
  },
  computed: {
    user() {
      return this.$store.getters.user || {};
    },
    isFeatureActive() {
      return this.user.user_type === "super-admin";
    },
  },
  methods: {
    getReleaseDate(date) {
      if ( date.length > 10) {
        return moment.utc(date).format("DD/MM/YYYY");
      }
      return date
    },

    getCurrencySymbol(currency) {
      switch (currency.toUpperCase()) {
        case "USD":
          return "$";
        case "EUR":
          return "€";
        case "GBP":
          return "£";
        case "AED":
          return "د.إ";
        case "NGN":
          return "₦";
        default:
          return "$";
      }
    },
    disabledBeforeTodayAndAfterAWeek(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date < today;
    },
    getPlans() {
      let fetch = {
        path: `/admin/subscription`,
      };
      this.loading = true;
      this.$store
        .dispatch("getRequest", fetch)
        .then((resp) => {
          const { data } = resp.data;
          this.allPlans = data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          if (err.response) {
            this.$vs.notify({
              title: "Error",
              text: err.response.data.message,
              color: "warning",
              icon: "error",
              position: "bottom-center",
            });
          } else {
            this.$vs.notify({
              title: "All Plans",
              text: "Unable to get Plans",
              color: "dark",
              icon: "error",
              position: "bottom-center",
            });
          }
        });
    },
    removeArtist() {
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm Delete`,
        text: `Are you sure you want to remove this Artist ?`,
        accept: this.removeConfirmed,
      });
    },
    removeConfirmed() {
      this.$vs.loading();
      let fetch = {
        path: `admin/users/delete/${this.$route.params.id}`,
        data: {},
      };
      this.$store
        .dispatch("deleteRequest", fetch)
        .then((resp) => {
          this.$toast.success(
            "Artist Delete",
            "Artist has been deleted successfully",
            this.$toastPosition
          );

          this.$vs.loading.close();
          setTimeout(() => {
            this.$router.push("/artists");
          }, 2000);
        })
        .catch((err) => {
          this.$vs.loading.close();
          if (err.response) {
            this.$toast.info(
              err.response.data.message,
              "Unable to delete Artist",
              this.$toastPosition
            );
          } else {
            this.$toast.info(
              "Something went wrong",
              "Unable to delete artist",
              this.$toastPosition
            );
          }
        });
    },
    getSocialLink(social) {
      if (social.platform == "instagram") {
        return `https://www.instagram.com/${social.link}`;
      } else if (social.platform == "twitter") {
        return `https://twitter.com/${social.link}`;
      } else if (social.platform == "facebook") {
        return `https://www.facebook.com/${social.link}`;
      }
    },
    updateRelease(status) {
      this.$vs.loading();
      let fetch = {
        path: `admin/incoming/${this.$route.params.id}/update`,
        data: {
          status,
        },
      };
      this.$store
        .dispatch("putRequest", fetch)
        .then((resp) => {
          this.$toast.success(
            "Release status updated successfully",
            "Release status updated",
            this.$toastPosition
          );

          this.$vs.loading.close();
        })
        .catch((err) => {
          this.$vs.loading.close();
          if (err.response) {
            this.$toast.success(
              err.response.data.message,
              "Release Status Update Failed",
              this.$toastPosition
            );
          } else {
            this.$toast.success(
              "Unable to update release status",
              "Release Status Update Failed",
              this.$toastPosition
            );
          }
        });
    },
    getArtistAnalytics() {
      this.loading = true;
      const uuid = this.$route.params.id;
      let fetch = {
        path: `/admin/users/analytics/${uuid}`,
      };
      this.$store
        .dispatch("getRequest", fetch)
        .then((resp) => {
          this.artistAnalytics = resp.data.data;

          const { platform_streams, platform_downloads } = this.artistAnalytics;

          this.infoChart.series[0].data = [
            {
              value: this.artistAnalytics.total_streams || 0,
              name: "Stream Count",
            },
            {
              value: this.artistAnalytics.total_downloads || 0,
              name: "Download Count",
            },
            {
              value: this.artistAnalytics.total_earnings || 0,
              name: "Earnings",
            },
          ];

          const streamKeys = Object.keys(platform_streams);
          const streamData = streamKeys.map((key) => {
            return platform_streams[key];
          });
          this.streamsChart.yAxis.data = streamKeys;
          this.streamsChart.series[0].data = streamData;

          const downloadKeys = Object.keys(platform_downloads);
          const downloadData = downloadKeys.map((key) => {
            return platform_downloads[key];
          });
          this.downloadsChart.yAxis.data = downloadKeys;
          this.downloadsChart.series[0].data = downloadData;

          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          if (err.response) {
            this.$vs.notify({
              title: "Release Info",
              text: err.response.data.message,
              color: "warning",
              icon: "error",
              position: "bottom-center",
            });
          } else {
            this.$vs.notify({
              title: "Release Info",
              text: "Unable to get Release",
              color: "dark",
              icon: "error",
              position: "bottom-center",
            });
          }
        });
    },
    getArtistbyID() {
      this.loading = true;
      const uuid = this.$route.params.id;
      let fetch = {
        path: `/admin/users/single/${uuid}`,
      };
      this.$store
        .dispatch("getRequest", fetch)
        .then((resp) => {
          this.artistData = resp.data.data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          if (err.response) {
            this.$vs.notify({
              title: "Release Info",
              text: err.response.data.message,
              color: "warning",
              icon: "error",
              position: "bottom-center",
            });
          } else {
            this.$vs.notify({
              title: "Release Info",
              text: "Unable to get Release",
              color: "dark",
              icon: "error",
              position: "bottom-center",
            });
          }
        });
    },
    playAudio(source, id) {
      this.$store.commit("audioPlay", {
        status: true,
        source,
        id,
      });
    },
    submitFormPlan() {
      const data = {
        plan_id: this.plan_id,
        end_date: moment(this.end_date).format("YYYY-MM-DD"),
      };
      const uuid = this.$route.params.id;
      let fetch = {
        path: `admin/users/change-plan/${uuid}`,
        data,
      };
      this.btnLoading = true;
      this.$store
        .dispatch("postRequest", fetch)
        .then((resp) => {
          this.$toast.success("Plan Update", "Success", this.$toastPosition);
          this.btnLoading = false;
          this.updatePlanModal = false;
          this.plan_id = null;
          this.end_date = null;
          this.getArtistbyID();
          this.getArtistAnalytics();
        })
        .catch((err) => {
          this.btnLoading = false;

          if (err.response) {
            this.$toast.info(
              err.response.data.message,
              "Unable to update plan",
              this.$toastPosition
            );
          } else {
            this.$toast.error(
              "An error occured while updating user plan",
              "Error",
              this.$toastPosition
            );
          }
        });
    },
  },
};
</script>
<style lang="scss">
.albumImg {
  border-radius: 10px;
}

.releaseData {
  .type {
    color: black;
    text-transform: uppercase;
    margin-bottom: 10px;
    letter-spacing: 2px;
  }

  .title {
    font-size: 5em;
    line-height: 1;
    font-weight: bold;
    color: black;
  }
}

.releaseinf {
  font-size: 14px;
  margin-bottom: 0px;

  .titl {
    color: black;
    letter-spacing: 1px;
    font-weight: bold;
  }
}

.metaDisplay {
  .headi {
    text-transform: uppercase;
    font-size: 12px;
    color: black;
    letter-spacing: 1px;
    border-radius: 26px;
    margin: 0;
  }

  .data {
    color: black;
  }
}

.chart {
  width: 100%;
  height: 400px;
}

.streamChart {
  width: 100%;
  height: 600px;
}

.spotify-card {
  background: #1ad860;
  cursor: pointer;
}

.plan-select {
  .con-vs-radio {
    justify-content: left;
    align-items: center;
  }
}

.mx-datepicker {
  width: 100%;
}

.mx-datepicker-popup {
  z-index: 99999;
}

.user-verified {
  position: relative;

  .vs-icon {
    background: #71b682;
    color: white;
    font-size: 17px;
    padding: 7px;
    border-radius: 44px;
    position: absolute;
    top: 0;
    right: 21px;
    border: 3px solid white;
  }
}
</style>
